export default [
  { name: '達麗東京', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '核心地段', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', mobileOffset: 0 },
  { name: '時尚地標', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', mobileOffset: 0 },
  { name: '空中泳池', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', mobileOffset: 0 },
  { name: '飯店公設', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', mobileOffset: 0 },
  { name: '頂規配備', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '', mobileOffset: 0 },
  { name: '品牌業績', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '', mobileOffset: 0 },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0 },
]

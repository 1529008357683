<template>
  <div class="section6 flex-ac">
     <!--  <div v-if="!isMobile" v-rellax="{
          // Rellax Options
          // See: https://github.com/dixonandmoe/rellax#features
          speed:-9
        }">
        <img src="./s6/bg.jpg" alt="img">
      </div> -->
   <div class="animate-slide">
      <img src="./s6/bg.jpg" :alt="`${info.caseName}_logo`" class="">
    </div>
    <!-- <img src="./s6/bg.jpg" :alt="`${info.caseName}_img`" class="bg-img"> -->
    <h3 class="title">
      國際視野高空泳池<br />
      城市之上我獨享
    </h3>
    <p class="subtitle">
      達麗給您無邊際想像！<br v-if="isPC">晨泳、消暑、池畔PARTY…會館人生。
    </p>
    <p class="name">
      空中泳池3D外觀合成模擬圖
    </p>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section6 {
  width: 100%;
  height: size(800);
  position: relative;
}

.animate-slide {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  transform: translateX(6%);
  img{
  height:100%;
  animation: an 20s 1s linear infinite alternate;}
}
@keyframes an {
  to {
    transform: translateX(17%);
  }
}

.bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  object-fit: cover;

  &:nth-child(1) {
    position: relative;
  }
}

.title {
  @include img_r_pc(400, 104, 318);
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.75);
  font-size: size(42);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.07em;
  text-align: right;
  color: #c8a064;
  white-space: nowrap;
}

.subtitle {
  @include img_r_pc(399, 240, 312);
  text-shadow: 7px 7px 5px rgba(0, 0, 0, 0.75);
  font-size: size(20);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: 0.05em;
  text-align: right;
  color: #fff;
}

.name {
  @include img_r_pc(1100, 0, 20);
  top: auto;
  bottom: size(20);
  text-shadow: 7px 7px 5px rgba(0, 0, 0, 0.75);
  font-size: size(20);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: size(8);
  text-align: right;
  color: #fff;
}
@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section6 {
    width: 100vw;
    height: sizem(460);
    min-height: auto;
    max-height: initial;
    margin: 0;
  &::before{
    content: "";z-index: 1;
  position: absolute;
    display: block;
    top: calc(60% -200vw);
    left:calc(30% - 80vw);
    width:160vw;
    height:400vw;
    background: radial-gradient(ellipse at center, rgba(63,40,86,1) 50%,rgba(63,40,86,0) 70%);
  }
  }

  .animate-slide {
    top: auto;
    bottom: 0;z-index: 2;
    height: sizem(249);
    display: flex;
  transform: translateX(19%);
    img {
      height: 100%;
    animation: an 15s 1s linear infinite alternate;}
  }
@keyframes an {
  to {
    transform: translateX(25%);
  }
}

  .border {
    @include div_c_m(335, 602, 30);z-index: 2;
    border: 2px solid #c9a063;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: sizem(34) sizem(34) 0 0;
      border-color: #c9a063 transparent transparent transparent;
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 sizem(34) sizem(34);
      border-color: transparent transparent #c9a063 transparent;
    }
  }

  .border-bottom {
    @include img_c_m(335, 0);
    top: auto;
    bottom: size(20);
    border: 2px solid #c9a063;z-index: 2;
  }

  .line {
    @include img_c_m(306, 157);z-index: 2;
  }

  .title {
    @include img_l_m(268, 30, 33);z-index: 2;
    font-size: sizem(25);
    text-align: left;
  }

  .subtitle {z-index: 2;
    @include img_c_m(310, 124);
    font-size: sizem(15);
    line-height: 1.8;
    text-align: left;
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'
import info from '@/info'
// import slider from '@/mixins/slider.js'

export default {
  name: 'section6',

  // mixins: [slider],

  data() {
    return {
      isPC,
      isMobile,
      isTablet,
      info,
      isDialog: false,
      // slideList: [
      //   {
      //     img: require('./s6/slider_3-1.jpg'),
      //     name: '家樂福商圈',
      //   },
      //   {
      //     img: require('./s6/slider_3-2.jpg'),
      //     name: '桃園藝文展演中心',
      //   },
      //   {
      //     img: require('./s6/slider_3-3.jpg'),
      //     name: '南崁COSTCO',
      //   },
      //   {
      //     img: require('./s6/slider_3-4.jpg'),
      //     name: '特力屋',
      //   },
      //   {
      //     img: require('./s6/slider_3-5.jpg'),
      //     name: '台茂購物中心',
      //   },
      // ],
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},

  watch: {},
}
</script>

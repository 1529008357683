<template>
  <div class="section5">
    <div class="swipe absolute" @mouseenter.stop="toggleTimer = false" @mouseleave.stop="toggleTimer = true">
      <div class="swipe-wrap relative" v-touch:swipe.left="decIndex" v-touch:swipe.right="addIndex">
        <transition-group name="swipe-fade" mode="out-in">
          <div v-for="(slide, i) in slideList" v-show="slideIndex === i" :key="slide.img" :class="`swipe-item absolute`">
            <img :src="slide.img" alt="">
            <p class="slide-name absolute" v-html="slide.name"></p>
          </div>
        </transition-group>
        <!-- <div class="pagination absolute flex-ac" data-aos="fade-up" data-aos-delay="200" v-if="isPC">
          <div :class="`pagination-dot`" v-for="(slide, index) in slideList" :key="slide.img + '-dot'" @click="goTo(index)"><span :class="`${slideIndex === index ? 'active' : ''}`"></span></div>
        </div> -->
        <div class="slide-btn s" v-if="slideIndex === 0" @click="goTo(1)">
          <svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
           <path d="M74.3,49.6c0,13.2-10.7,23.9-23.9,23.9S26.5,62.8,26.5,49.6s10.7-23.9,23.9-23.9S74.3,36.4,74.3,49.6z M41.9,79 l8.3,8.3l8.3-8.3H41.9z M58.4,20.2l-8.3-8.3l-8.3,8.3H58.4z M71.6,71.7l11.3-3l-3-11.3L71.6,71.7z M29,27.9l-11.3,3l3,11.3L29,27.9z M80.1,42.3l3-11.3l-11.3-3L80.1,42.3z M20.9,57.3l-3,11.3l11.3,3L20.9,57.3z"/>
          </svg></div>
        <div class="slide-btn n" v-if="slideIndex === 1" @click="goTo(0)">
          <svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
           <path d="M49.1,49.9c0-12.5,8.2-23,19.4-26.7c-3.5-1.5-7.4-2.3-11.4-2.3c-16,0-29,13-29,29s13,29,29,29 c4.1,0,7.9-0.8,11.4-2.3C57.3,72.9,49.1,62.3,49.1,49.9z"/>
          </svg>
        </div>
        <!-- 
        <img src="./s5/n.png" alt="" class="slide-btn" v-if="slideIndex === 0" @click="goTo(1)">
        <img src="./s5/s.png" alt="" class="slide-btn" v-if="slideIndex === 1" @click="goTo(0)">
        <div class="swipe-btns absolute flex-ac flex-jb">
          <img src="./all/arrow-prev.png" alt="" class="prev-btn" @click="decIndex">
          <img src="./all/arrow-next.png" alt="" class="next-btn" @click="addIndex">
        </div> -->
      </div>
    </div>
    <div class="border"  v-if="!isMobile">
    </div>
    <h3 class="title" v-if="!isMobile">
      高雄晴空地標 東京時尚天際
    </h3>
    <div class="line" v-if="!isMobile"></div>
    <p class="subtitle" v-if="!isMobile">
      全新落成、高雄市中心頂級視野，超高精品地標展魅力！<br />
      俐落風格、簡約時尚，城市、行人、居住者視角全方位！<br />
      高雄稀有蛋黃，頂尖景觀生活，現在馬上入住、馬上起漲！
    </p>
    <div class="border" v-if="isMobile">
    <h3 class="title">
      高雄晴空地標<br /> 東京時尚天際
    </h3>
    <div class="line"></div>
    <p class="subtitle">
      全新落成、高雄市中心頂級視野，超高精品地標展魅力！
      俐落風格、簡約時尚，城市、行人、居住者視角全方位！
      高雄稀有蛋黃，頂尖景觀生活，現在馬上入住、馬上起漲！
    </p>
    </div>
    <div class="border-bottom">
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
.section5 {
  width: 100%;
  height: size(1165);
  position: relative;
  &::before{
    content: "";z-index: 1;
  position: absolute;
    display: block;
    bottom: 0;
    left:0;
    width:100%;
    height:80vw;
    background:  linear-gradient(to top, rgba(63,40,86,1) 0%,rgba(63,40,86,0) 100%);
  }
}

.bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  object-fit: cover;
}


.border {
  @include div_c_pc(1500, 850, 180);
  border: 1px solid #c9a063;z-index: 3;
 
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
      border-width: 2.26em 2.26em 0 0;
    border-color: #c9a063 transparent transparent transparent;
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
      border-width: 0 0 2.26em 2.26em;
    border-color: transparent transparent #c9a063 transparent;
  }
}

.border-bottom {
  @include img_c_pc(1190, 0);
  top: auto;
  bottom: size(40);
  border: 2px solid #c9a063;
}

.line {
  @include img_l_pc(128, 50, 848);
  transform: rotate(90deg);z-index: 3;
}

.title {
  @include img_l_pc(566, 25, 250);
  font-size: size(42);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.07em;
  text-align: center;
  color: #c8a064;
  white-space: nowrap;z-index: 3;
  text-shadow: 0 0 2vw #3E2855, 0 0 1vw #3E2855, 0 0 1vw #3E2855;
}

.subtitle {
  @include img_r_pc(927, 0, 322);
  font-size: size(20);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.05em;
  text-align: right;
  color: #fff;z-index: 3;
  text-shadow: 0 0 2vw #3E2855, 0 0 1vw #3E2855, 0 0 1vw #3E2855;
}

/* Swipe */
.swipe {
  @include div_c_pc(1600, 940, 130);
}

// begin
.swipe-fade-leave-to {
  opacity: 0;
  z-index: 0;
}
// end
.swipe-fade-enter {
  opacity: 0;
  z-index: 1;
}

.swipe-fade-enter-active {
  transition: all 0.5s ease;
}

.swipe-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

// begin
// .swipe-left-leave-to {
//   margin-left: -100vw;
//   z-index: 0;
// }
// // end
// .swipe-left-enter {
//   opacity: 0.5;
//   margin-left: 0;
//   z-index: 1;
// }

// .swipe-left-enter-active {
//   transition: all 0.5s ease;
// }

// .swipe-left-leave-active {
//   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
// }

.swipe-wrap {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}

.slide-btn {
  @include div_r_pc(100,100, 30, 30);
  cursor: pointer;
  z-index: 10;
  border-radius: 50%;
  transition:color 0.3s;
  box-shadow: 0 0 .5vw #ffF9,0 0 3vw #fC39;
  background: radial-gradient(ellipse at center, #623F93 0%,#3E2855 60%);
  svg{fill: #FFF;
  transition:fill .5s;}
  &:hover{
  background: radial-gradient(ellipse at center, #C1ADD6 0%,#FFF 60%);
    svg{fill: #3E2855;}
  }
  &.n{
  background: radial-gradient(ellipse at center, #C1ADD6 0%,#FFF 60%);
  svg{fill: #3E2855;
  transition:fill .5s;}
  &:hover{
  background: radial-gradient(ellipse at center, #623F93 0%,#3E2855 60%);
    svg{fill: #FFF;}
  }
  }
}

.swipe-item {
  width: 100%;
  height: 100%;
  z-index: 0;z-index: 3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slide-name {
    right: size(50);
    bottom: 0px;
    color: #fff;
    text-shadow: 0 0 2.5vw rgba(0, 0, 0, 1), 0 0 2vw rgba(0, 0, 0, 1),0 0 1vw rgba(0, 0, 0, 1);
    font-size:size(15);
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: 0.89px;
    text-align: left;
    color: #ffffff;
  }

  // &:nth-child(1) {
  //   z-index: 1;
  //   // opacity: 1;
  // }

  // &.base {
  //   z-index: 1;
  //   opacity: 1;
  // }
  // &.active {
  //   z-index: 2;
  //   // opacity: 1;
  // }
}

.pagination {
  width: auto;
  top: auto;
  left: 0;
  right: 0;
  bottom: size(-60);
  z-index: 2;
  justify-content: center;
}

.pagination-dot {
  padding: 5px;
  margin: 0 5px;
  cursor: pointer;
  z-index: 4;

  span {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    box-shadow: 0 0 0 1px #fff;
    position: relative;
    background-color: transparent;
    transition: all 0.5s;

    &::before {
      content: '';
      width: 60%;
      height: 60%;
      display: block;
      background: transparent;
      border-radius: 20px;
      opacity: 1;
      position: absolute;
      top: 20%;
      // transform: translateY(-50%);
      left: 20%;
      transition: all 0.3s;
      transform-origin: center;
      transform: scale(0);
    }
    &.active {
      box-shadow: 0 0 0 1px #fff;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: #fff;
        border-radius: 20px;
        opacity: 1;
        position: absolute;
        top: 0%;
        // transform: translateY(-50%);
        left: 0%;
        transform: scale(1);
      }
    }
  }
}

.swipe-btns {
  width: 100%;
  height: 100%;
  padding: 0 15px;
  z-index: 3;

  .prev-btn,
  .next-btn {
    width: size(72);
    cursor: pointer;
  }
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section5 {
    //height: sizem(578 + 100);
   height: auto;margin: 0 0 12vw;
  &::before{
    top: calc(60% -200vw);
    left:calc(30% - 80vw);
    width:160vw;
    height:400vw;
    background: radial-gradient(ellipse at center, rgba(63,40,86,1) 50%,rgba(63,40,86,0) 70%);
  }
  }

  .border {
    @include img_c_m(335, 0);
    height: auto;
    position: relative;
    margin:2em auto ;
    border:1px solid #c9a063;
    font-size: sizem(15);
    padding:2em 1.2em;

  }

  .border-bottom {
    @include img_c_m(335, 0);
    top: auto;z-index: 2;
    bottom: sizem(-45);
    border: 1px solid #c9a063;
  }

  .line {
    @include img_c_m(306, 0);
    position: relative;
    margin:1em 0;
    transform: rotate(0);z-index: 2;
  }
.title {
    position: relative;
    top: 0;left: auto; right: auto;
    width: 100%;
    font-size: sizem(25);
    text-align: left;
  }
  .subtitle {
    position: relative;left: auto; right: auto;
    top: 0;
    width: 100%;
    font-size: sizem(15);
    line-height: 1.8;
    text-align: justify;
    color: #fff;
    white-space: normal;
    padding-bottom: 16em;
  }


  /* Swipe */
  .swipe {
    @include div_c_m(355, 250, 326);
    bottom:sizem(-11) ;
    top: auto;
  }

  .slide-btn {
    @include div_r_m(50,50, -30, 30);
    cursor: pointer;
  }

  // begin
  .swipe-fade-leave-to {
    opacity: 0;
  }
  // end
  .swipe-fade-enter {
    opacity: 0;
  }

  .swipe-fade-enter-active {
    transition: all 0.5s ease;
  }

  .swipe-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  // begin
  // .swipe-left-leave-to {
  //   margin-left: -100vw;
  //   z-index: 0;
  // }
  // // end
  // .swipe-left-enter {
  //   opacity: 0.5;
  //   margin-left: 0;
  //   z-index: 1;
  // }

  // .swipe-left-enter-active {
  //   transition: all 0.5s ease;
  // }

  // .swipe-left-leave-active {
  //   transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  // }

  .swipe-wrap {
    width: 100%;
    height: 100%;
    // overflow: hidden;
  }

  .swipe-item {
    width: 100%;
    height: 100%;index: 3;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .slide-name {
      left: sizem(20);
      bottom: sizem(5);
      color: #fff;
      text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 3.11;
      letter-spacing: 0.89px;
      text-align: left;
      color: #ffffff;
    }
  }

  .pagination {
    width: auto;
    top: auto;
    left: 0;
    right: 0;
    bottom: size(-60);
    justify-content: center;
  }

  .pagination-dot {
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;

    span {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      box-shadow: 0 0 0 1px #fff;
      position: relative;
      background-color: transparent;
      transition: all 0.5s;

      &::before {
        content: '';
        width: 60%;
        height: 60%;
        display: block;
        background: transparent;
        border-radius: 20px;
        opacity: 1;
        position: absolute;
        top: 20%;
        // transform: translateY(-50%);
        left: 20%;
        transition: all 0.3s;
        transform-origin: center;
        transform: scale(0);
      }
      &.active {
        box-shadow: 0 0 0 1px #fff;
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          background: #fff;
          border-radius: 20px;
          opacity: 1;
          position: absolute;
          top: 0%;
          // transform: translateY(-50%);
          left: 0%;
          transform: scale(1);
        }
      }
    }
  }

  .swipe-btns {
    width: 100%;
    height: 100%;
    padding: 0 15px;index: 5;

    .prev-btn,
    .next-btn {
      width: sizem(37);
      cursor: pointer;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'
import info from '@/info'
import slider from '@/mixins/slider.js'

export default {
  name: 'section5',

  mixins: [slider],

  data() {
    return {
      isPC,
      isMobile,
      isTablet,
      info,
      slideList: [
        {
          img: require('./s5/1.jpg'),
          name: '3D外觀合成模擬圖',
        },
        {
          img: require('./s5/2.jpg'),
          name: '3D外觀合成模擬圖',
        },
      ],
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},

  watch: {},
}
</script>

// import { isMobile } from '@/utils'

export default {
  address: '高雄市前金區六合二路98號',
  // isMobile ? '接待中心：<br />106台北市大安區基隆路三段20-1號' : '接待中心：106台北市大安區基隆路三段20-1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.5978768444847!2d120.29473741496074!3d22.63148678515263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e0462730e6e33%3A0xa2a57b60c8d2aaae!2z6YGU6bqX5p2x5Lqs!5e0!3m2!1szh-TW!2stw!4v1631777784468!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/zNvmJqyyn74WXLtA6',
  phone: '07-2852111',
  fbLink:
    'https://www.facebook.com/達麗東京-461376924235234',
  fbMessage: 'https://m.me/461376924235234/',
  caseName: '達麗東京',
  houseInfos: [
    ['投資興建', '達麗建設事業股份有限公司'],
    ['建築規劃', '407戶住家，９戶店面'],
    ['使照號碼', '(110)高市工建築使字第01284號'],
    ['工程營造', '寶信營造股份有限公司'],
    ['廣告銷售',  "<img src='"+ require('@/assets/img/houseinfo-sales.png')+"'>"],
    ['接待會館', '高雄市前金區六合二路98號'],
  ],
  gtmCode: ['52F5VGR'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: '若想了解建案資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！'
  },
}
